import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IContract } from 'src/app/core/models/provisioning.model';
import { IApplicationInfo, ICredentials, ISite, ISiteExtractorCredential, ISiteForm , IStatus } from 'src/app/core/models/site-management.model';

export const SiteManagementApiActions = createActionGroup({
  source: 'Site Management API',
  events: {
    'Load Application Info': props<{ tenantId: string, applicationInfo: string }>(),
    'Load Application Info Success': props<{ applicationInfo: IApplicationInfo }>(),
    'Load Application Info Failure': props<{ error: any }>(),
    'Load Contracts': props<{ tenantId: string }>(),
    'Load Contracts Success': props<{ contracts: IContract[] }>(),
    'Load Contracts Failure': props<{ error: any }>(),
    'Load Extractor Data': props<{ tenantId: string, siteId: string; }>(),
    'Load Extractor Data Success': props<{ siteExtractorCredentials: ISiteExtractorCredential[] }>(),
    'Load Extractor Data Failure': props<{ error: any }>(),
    'Regenerate Extractor Credentials': props<{ tenantId: string, extractorId: string }>(),
    'Regenerate Extractor Credentials Success': props<{ credentials: ICredentials, extractorId: string }>(),
    'Regenerate Extractor Credentials Failure': props<{ error: any }>(),
    'Regenerate Application Credentials': props<{ tenantId: string, applicationId: string }>(),
    'Regenerate Application Credentials Success': props<{ credentials: ICredentials }>(),
    'Regenerate Application Credentials Failure': props<{ error: any }>(),
    'Create Site': props<{ tenantId: string, site: ISiteForm }>(),
    'Create Site Success': props<{ site: ISite }>(),
    'Create Site Failure': props<{ error: any }>(),
    'Update Site': props<{ tenantId: string, siteId: string, site: ISiteForm }>(),
    'Update Site Success': props<{ site: ISite }>(),
    'Update Site Failure': props<{ error: any }>(),
    'Load Sites': props<{ tenantId: string ,resetSelectedSite: boolean}>(),
    'Delete Site': props<{ tenantId: string ,siteId: string , siteName: string , resetSelectedSite: boolean}>(),
    'Delete Site Failure':  props<{ siteName: string}>(),
    'Delete Site Success':  props<{ siteName: string , resetSelectedSite: boolean}>(),
    'Load Sites Success': props<{ sites: ISite[] ,resetSelectedSite: boolean}>(),
    'Load Sites Failure': props<{ error: any }>(),
    'Set Current Site': props<{ site: ISite | null  }>(),
    'Set Update Site Info': props<{ site: ISite | null }>(),
    'Empty Created Site': emptyProps(),
    'Empty Delete Status': emptyProps(),
    'Empty Updated Site': emptyProps(),
    'Empty Update Site Info': emptyProps(),
    'Empty Sites': emptyProps(),
    'load Function Status': props<{tenantId: string}>(),
    'load Function Status Success': props<{status: IStatus}>(),
    'load Function Status Failure': props<{error: any}>(),
  }
});