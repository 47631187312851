import { createReducer, on } from '@ngrx/store';
import {
  IApplicationInfo,
  IExtractorCredentials,
  IExtractorCredentialsConfig,
  ISite, IStatus ,IDeleteStatus
} from 'src/app/core/models/site-management.model';
import { SiteManagementApiActions } from '../../actions/site-management-api.actions';
import { IContract } from 'src/app/core/models/provisioning.model';
import { IError } from 'src/app/core/models/general.model';

export interface SiteManagementApiState {
  applicationInfo: IApplicationInfo | null;
  createdSite: ISite | null;
  updatedSite: ISite | null;
  updateSiteInfo: ISite | null;
  contracts: IContract[];
  sites: ISite[];
  functionStatus: IStatus;
  currentSite: ISite | null;
  extractorCredentialsConfig: IExtractorCredentialsConfig[];
  extractorCredentials: IExtractorCredentials[];
  deleteStatus: IDeleteStatus,
  loading: {
    applicationInfo: boolean;
    contracts: boolean;
    sites: boolean;
    extractorCredentials: boolean;
    createSite: boolean;
    updateSite: boolean;
    functionStatus: boolean;
    deleteStatus: boolean;
  };
  error: {
    applicationInfo: IError | undefined;
    sites: IError | undefined;
    contracts: IError | undefined;
    extractorCredentials: IError | undefined;
    createSite: IError | undefined;
    updateSite: IError | undefined;
    functionStatus: IError | undefined;
  };
}

export const initialState: SiteManagementApiState = {
  applicationInfo: null,
  createdSite: null,
  updatedSite: null,
  updateSiteInfo: null,
  contracts: [],
  sites: [],
  functionStatus: {
    functionStatus: ''
  },
  currentSite: null,
  extractorCredentials: [],
  extractorCredentialsConfig: [],
  loading: {
    applicationInfo: false,
    sites: false,
    contracts: false,
    createSite: false,
    updateSite: false,
    extractorCredentials: false,
    functionStatus: false,
    deleteStatus: false
  },
  deleteStatus: {
    status: undefined,
    message: ""
  },
  error: {
    applicationInfo: undefined,
    sites: undefined,
    contracts: undefined,
    extractorCredentials: undefined,
    createSite: undefined,
    updateSite: undefined,
    functionStatus: undefined
  },
};

export const reducer = createReducer(
  initialState,
  on(SiteManagementApiActions.loadApplicationInfo, (state) => {
    return {
      ...state,
      applicationInfo: null,
      loading: {
        ...state.loading,
        applicationInfo: true,
      },
      error: {
        ...state.error,
        applicationInfo: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.loadApplicationInfoSuccess, (state, action) => {
    return {
      ...state,
      applicationInfo: action.applicationInfo,
      loading: {
        ...state.loading,
        applicationInfo: false,
      },
    };
  }),
  on(SiteManagementApiActions.loadApplicationInfoFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        applicationInfo: false,
      },
      error: {
        ...state.error,
        applicationInfo: action.error,
      },
    };
  }),
  on(SiteManagementApiActions.loadContracts, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        contracts: true,
      },
      error: {
        ...state.error,
        contracts: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.loadContractsSuccess, (state, action) => {
    return {
      ...state,
      contracts: [...action.contracts],
      loading: {
        ...state.loading,
        contracts: false,
      },
    };
  }),
  on(SiteManagementApiActions.loadContractsFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        contracts: false,
      },
      error: {
        ...state.error,
        contracts: { ...action.error /*.error*/ },
      },
    };
  }),
  on(SiteManagementApiActions.regenerateExtractorCredentials, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        extractorCredentials: true,
      },
      error: {
        ...state.error,
        extractorCredentials: undefined,
      },
    };
  }),
  on(
    SiteManagementApiActions.regenerateExtractorCredentialsSuccess,
    (state, action) => {
      const { credentials, extractorId } = action;
      const updatedExtractors = state.extractorCredentials.map((extractor) => {
        return {
          ...extractor,
          clientSecret:
            extractor.id === extractorId
              ? credentials.client_secret
              : extractor.clientSecret,
        };
      });
      return {
        ...state,
        extractorCredentials: [...updatedExtractors],
        loading: {
          ...state.loading,
          extractorCredentials: false,
        },
      };
    }
  ),
  on(
    SiteManagementApiActions.regenerateExtractorCredentialsFailure,
    (state, action) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          extractorCredentials: false,
        },
        error: {
          ...state.error,
          extractorCredentials: { ...action.error /*.error*/ },
        },
      };
    }
  ),
  on(SiteManagementApiActions.regenerateApplicationCredentials, (state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        applicationInfo: true,
      },
      error: {
        ...state.error,
        applicationInfo: undefined,
      },
    };
  }),
  on(
    SiteManagementApiActions.regenerateApplicationCredentialsSuccess,
    (state, action) => {
      const { credentials } = action;
      return {
        ...state,
        applicationInfo: state.applicationInfo
          ? {
            ...state.applicationInfo,
            clientSecret: credentials.client_secret,
          }
          : null,
        loading: {
          ...state.loading,
          applicationInfo: false,
        },
      };
    }
  ),
  on(
    SiteManagementApiActions.regenerateApplicationCredentialsFailure,
    (state, action) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          applicationInfo: false,
        },
        error: {
          ...state.error,
          applicationInfo: { ...action.error.error },
        },
      };
    }
  ),
  on(SiteManagementApiActions.loadExtractorData, (state) => {
    return {
      ...state,
      extractorCredentials: [],
      extractorCredentialsConfig: [],
      loading: {
        ...state.loading,
        extractorCredentials: true,
      },
      error: {
        ...state.error,
        extractorCredentials: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.loadExtractorDataSuccess, (state, action) => {
    const extractorCredentials = action.siteExtractorCredentials.map(
      (siteExtract) => siteExtract.extractorInfo
    );
    const extractorCredentialsConfig = action.siteExtractorCredentials.map(
      (siteExtract) => siteExtract.configValues
    );
    return {
      ...state,
      extractorCredentialsConfig,
      extractorCredentials,
      loading: {
        ...state.loading,
        extractorCredentials: false,
      },
    };
  }),
  on(SiteManagementApiActions.loadExtractorDataFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        extractorCredentials: false,
      },
      error: {
        ...state.error,
        extractorCredentials: { ...action.error /*.error*/ },
      },
    };
  }),
  on(SiteManagementApiActions.createSite, (state) => {
    return {
      ...state,
      createdSite: null,
      loading: {
        ...state.loading,
        createSite: true,
      },
      error: {
        ...state.error,
        createSite: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.createSiteSuccess, (state, action) => {
    return {
      ...state,
      createdSite: { ...action.site },
      loading: {
        ...state.loading,
        createSite: false,
      },
    };
  }),
  on(SiteManagementApiActions.createSiteFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        createSite: false,
      },
      error: {
        ...state.error,
        createSite: { ...action.error.error },
      },
    };
  }),
  
  on(SiteManagementApiActions.deleteSite, (state) => {
    return {
      ...state,
      deleteStatus: {
        status: undefined,
        message: ""
      },
      loading: {
        ...state.loading,
        deleteStatus: true,
      }
    };
  }),
  on(SiteManagementApiActions.deleteSiteSuccess, (state, action) => {
    return {
      ...state,
      deleteStatus: {
        status: "success",
        message: `${action.siteName} deleted successfully.`
      },
      loading: {
        ...state.loading,
        deleteStatus: false,
      }
    }
  }),
  on(SiteManagementApiActions.deleteSiteFailure, (state, action) => {
    return {
      ...state,
      deleteStatus: {
        status: "failed",
        message: `${action.siteName} deletion failed.`
      },
      loading: {
        ...state.loading,
        deleteStatus: false,
      }
    };
  }),
  on(SiteManagementApiActions.updateSite, (state) => {
    return {
      ...state,
      updatedSite: null,
      loading: {
        ...state.loading,
        updateSite: true,
      },
      error: {
        ...state.error,
        updateSite: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.updateSiteSuccess, (state, action) => {
    return {
      ...state,
      updatedSite: { ...action.site },
      loading: {
        ...state.loading,
        updateSite: false,
      },
    };
  }),
  on(SiteManagementApiActions.updateSiteFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        updateSite: false,
      },
      error: {
        ...state.error,
        updateSite: { ...action.error.error },
      },
    };
  }),
  on(SiteManagementApiActions.loadSites, (state, action) => {
    return {
      ...state,
      sites: [],
      currentSite: action.resetSelectedSite ? null : state.currentSite,
      loading: {
        ...state.loading,
        sites: true,
      },
      error: {
        ...state.error,
        sites: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.loadSitesSuccess, (state, action) => {
    let site = undefined;
    if (action.sites && action.sites.length > 0) {
      site = action.sites.find((e) => e.provisioningStep === 'SITE_PROVISIONING' && e.provisioningStatus === 'COMPLETED');
    };
    if (!action.resetSelectedSite) {
      site = state.currentSite;
    }
    return {
      ...state,
      sites: [...action.sites],
      currentSite: site ? site : null,
      loading: {
        ...state.loading,
        sites: false,
      },
    };
  }),
  on(SiteManagementApiActions.loadSitesFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        sites: false,
      },
      error: {
        ...state.error,
        sites: { ...action.error.error },
      },
    };
  }),
  on(SiteManagementApiActions.setCurrentSite, (state, action) => {
    return {
      ...state,
      currentSite: action.site,
    };
  }),
  on(SiteManagementApiActions.setUpdateSiteInfo, (state, action) => {
    return {
      ...state,
      updateSiteInfo: action.site,
    };
  }),
  on(SiteManagementApiActions.emptyDeleteStatus, (state) => {
    return {
      ...state,
      createdSite: null,
      deleteStatus: {
        ...state.deleteStatus,
        status: undefined,
        message: ""
      },
      loading: {
        ...state.loading,
        deleteStatus: false,
      }
    };
  }),
  on(SiteManagementApiActions.emptyCreatedSite, (state) => {
    return {
      ...state,
      createdSite: null,
      error: {
        ...state.error,
        createSite: undefined,
        updateSite: undefined,
        extractorCredentials: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.emptyUpdatedSite, (state) => {
    return {
      ...state,
      updatedSite: null,
      error: {
        ...state.error,
        updateSite: undefined,
        contracts: undefined,
      }
    };
  }),
  on(SiteManagementApiActions.emptyUpdateSiteInfo, (state) => {
    return {
      ...state,
      updateSiteInfo: null,
      extractorCredentials: [],
      extractorCredentialsConfig: [],
      applicationInfo: null,
      error: {
        ...state.error,
        createSite: undefined,
        updateSite: undefined,
        extractorCredentials: undefined,
        contracts: undefined,
      }
    };
  }),
  on(SiteManagementApiActions.emptySites, (state) => {
    return {
      ...state,
      sites: [],
      error: {
        ...state.error,
        sites: undefined
      }
    };
  }),
  on(SiteManagementApiActions.loadFunctionStatus, (state) => {
    console.info(state);
    return {
      ...state,
      // functionStatus: undefined,
      loading: {
        ...state.loading,
        functionStatus: true,
      },
      error: {
        ...state.error,
        functionStatus: undefined,
      },
    };
  }),
  on(SiteManagementApiActions.loadFunctionStatusSuccess, (state, action) => {
    return {
      ...state,
      functionStatus: action.status,
      loading: {
        ...state.loading,
        functionStatus: false,
      },
    };
  }),
  on(SiteManagementApiActions.loadFunctionStatusFailure, (state, action) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        functionStatus: false,
      },
      error: {
        ...state.error,
        functionStatus: { ...action.error.error },
      },
    };
  }),
);
