import { createActionGroup, props , emptyProps } from '@ngrx/store';
import { ITreeModel, DeviceDashboardReqModel, DeviceTableReqModel, DeviceInformationReqModel, IDeviceStatisticsInformationEventDetail } from 'src/app/core/models/device-statistics.model';
export const DeviceStatisticsApiActions = createActionGroup({
    source: 'Device Statistics API',
    events: {
        'Load FTAE': props<{ tenantId: string , siteId: number }>(),
        'Load FTAE Success': props<{ data: ITreeModel[] }>(),
        'Load FTAE Failure': props<{ error: any }>(),
        'Set Selected Device': props<{ item: ITreeModel | null}>(),
        'Load PIAF': props<{ tenantId: string  , siteId: number}>(),
        'Load PIAF Success': props<{ data: ITreeModel[] }>(),
        'Load PIAF Failure': props<{ error: any }>(),
        'Toggle Drawer': props<{ toggleState: boolean }>(),
        'Toggle Source': props<{ source_type: "FTAE" | "PIAF" }>(),
        'Load Devices Dashboard': props<{ payload: DeviceDashboardReqModel }>(),
        'Load Devices Success': props<{ data: any, nodeId: string, nodeType: string }>(),
        'Load Devices Failure': props<{ error: any }>(),
        'Load Device Controller Alarms Chart': props<{ payload: DeviceDashboardReqModel}>(),
        'Load Device Controller Alarms Chart Success': props<{ items: any, nodeId: string, nodeType: string, showPriorityChart: boolean}>(),
        'Load Device Controller Alarms Chart Failure': props<{ error: any }>(),
        'Load Devices Table': props<{ payload: DeviceTableReqModel, reload?: boolean }>(),
        'Set Empty Devices Table': emptyProps(),
        'Load Devices Table Success': props<{ items: any, cursor: string,  isAgridShow: boolean }>(),
        'Load Devices Table Failure': props<{ error: any }>(),
        'Load Devices Summary': props<{ payload: any }>(),
        'Load Devices Summary Success': props<{ data: any }>(),
        'Load Devices Summary Failure': props<{ error: any }>(),
        'Load Devices Information Head': props<{ payload: any }>(),
        'Load Devices Information Head Success': props<{ logsCount: number, objectPath: string }>(),
        'Load Devices Information Head Failure': props<{ error: any }>(),
        'Load Devices Information': props<{ payload: DeviceTableReqModel, reload?: boolean }>(),
        'Load Devices Information Success': props<{ items: any, nextCursor: string, deviceDropdown: any , diagnoslicLogsCount: number, showDeviceInfo: boolean}>(),
        'Load Devices Information Failure': props<{ error: any }>(),
        'Set Selected Device Information Event': props<{ selectedDeviceInfoEvent: IDeviceStatisticsInformationEventDetail }>(),
        'Load Alarms Tab Table': props<{ payload: DeviceTableReqModel, reload?: boolean }>(),
        'Load Alarms Tab Success': props<{ aGridData: any, nextCursor: string, isAgridShow: boolean }>(),
        'Load Alarms Tab Failure': props<{ error: any }>(),
        'Load Device Alarms Chart': props<{ payload: DeviceDashboardReqModel }>(),
        'Load Device Alarms Chart Success': props<{ items: any, nodeId: string, nodeType: string, showPriorityChart : boolean }>(),
        'Load Device Alarms Chart Failure': props<{ error: any }>(),
        'Update Device Information': props<{payload: any}>(),
        'Update Device Information Success': props<{success: boolean}>(),
        'Update Device Information Failure': props<{error: any}>(),
        'Clear Device Summary Error': emptyProps(),
    }
});
