import { Shift } from './configuration.model';
import { IDatesFilter, IPagination, ResponseMapper } from './general.model';

export enum ToggleDateFilter {
  MONTH = 'MONTH',
  DAY = 'DAY',
  SHIFT = 'SHIFT'
};
export enum ToggleDateFilterNew {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  SHIFT = 'SHIFT',
  YEAR = 'year',
  CUSTOM = 'custom'
};
export enum AlarmGroupBy {
  START_DATE = 'startDate',
  START_HOUR = 'startHour',
  START_TEN_MINUTES_PERIOD = 'startTenMinutesPeriod',
  PRIORITY = 'priority',
  ALARM_CLASS = 'alarmClass',
  STATE = 'state',
  SEVERITY = 'severity',
  AREA = 'area',
  GROUP = 'group',
  FILTER_SOURCE = 'filterSource',
}

export enum AlarmState {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  SHELVED = 'SHELVED',
  SUPPRESSED = 'SUPPRESSED',
  ACKNOWLEDGE = 'ACKED',
}

export enum Sort {
  DESC = 'DESC',
  ASC = 'ASC'
}
export type ITimeLIne = "year" | "day" | "month" | "week" | "custom"
export interface IAlarmFilters extends IDatesFilter {
  sitesIds: {
    id: number;
  };
  area: string | null;
  group: string | null;
  timeLine: ITimeLIne;
  shift: Shift | null | 'all';
  processObj?: string | null | 'all';
  state?: AlarmState | null;
  priority?: string | null;
  eventType?: AlarmEventType | null;
  sort?: Sort;
  previous?: boolean | null;
  aggType?: string | 'class';
}

export type IAlarmPagination = IPagination;

export interface IAlarmRequest {
  tenantId: string;
  groupBy?: AlarmGroupBy;
  filters: IAlarmFilters;
  pagination?: IAlarmPagination;
  type?: string;
  currentTime?: string;
}
export interface AlarmCountStackedChartResponse {
  count: number;
  data: ResponseMapper[],
  name: string;
  percentage: string;
  groupBy?: AlarmGroupBy;
  formatDates: boolean;
}
export interface DrilldownRow {
  alarm: string;
  time: string;
}
export interface DrilldownTableResponse<T> {
  items: DrilldownRow[],
  nextCursor: string
}
export interface DrilldownTableData {
  subheading: string;
  data: DrilldownRow[];
  cursor: string;
}
export interface DrilldownChartData {
  type: 'daily' | 'hourly' | 'separate_stacks' | 'monthly';
  breadCrumbData: string[];
  dateRange: string[];
  category?: string | null;
}
export interface AlarmCountResponseMapper {
  alarmArea: AlarmCountStackedChartResponse[];
  alarmClass: AlarmCountStackedChartResponse[];
  alarmGroup: AlarmCountStackedChartResponse[];
  alarmPriority: AlarmCountStackedChartResponse[];
  alarmState: AlarmCountStackedChartResponse[];
  totalCount: number;
  formatDates: boolean;
}
export type IAlarmGroupByResponse = ResponseMapper;
export type IAlarmKPIItem = ResponseMapper;

export interface IAlarmGroupedBy {
  totalAlarmCount: AlarmCountResponseMapper;
  totalAlarmClassCount: [];
  totalAlarmStateCount: IAlarmGroupByResponse[];
  totalAlarmPriorityCount: IAlarmGroupByResponse[];
  totalAlarmAreaCount: IAlarmGroupByResponse[];
}

export interface ITopAlarmBy {
  frequency: IAlarmGroupByResponse[];
  duration: IAlarmGroupByResponse[];
}

export interface IAlarmForm {
  dateFilter: string;
  areaFilter: string;
  groupFilter: string;
  processObjectFilter?: string;
  shiftFilter: Shift | null | 'all';
  reportBy?: AlarmEventType;
}
export interface IAutomaticDiagnosticForm {
  startDate: string;
  endDate: string;
}

export interface IAlarm {
  alarm: string;
  activeTime: string;
  inactiveTime: string;
  serverName: string;
  duration: string;
  message: string;
  priority: string;
  lastUpdated?: string;
}

export interface IDetailedAlarm {
  alarm: string;
  activeTime: string;
  inactiveTime: string;
  serverName: string;
  duration: string;
  message: string;
  priority: string;
}

export interface IDetailedAlarmDetail extends IDetailedAlarm {
  state: string;
  attribute: string;
  area: string;
  eventType: string;
  eventCategory: string;
  ticksTimeStamp: string;
  eventTimeStamp: string;
  sourceId: string;
  sourcePath: string;
  sourceName: string;
  conditionName: string;
  subConditionName: string;
  changeMask: string;
  inputValue: string;
  limitValue: string;
  quality: string;
  severity: string;
  alarmClass: string;
  tag1Value: string;
  tag2Value: string;
  tag3Value: string;
  tag4Value: string;
  computerId: string;
  personId: string;
  userComment: string;
  autoUnshelveTime: string;
  groupPath: string;
}
export interface IChatteringAlarm {
  alarm: string;
  activeTime: string;
  duration: string;
  message: string;
  averageAlarmTime: string;
  chatteringCount: number;
}

export interface IFleetingAlarm {
  alarm: string;
  activeTime: string;
  inactiveTime: string;
  duration: string;
  message: string;
}

export interface IFloodingAlarm {
  alarm: string;
  activeTime: string;
  inactiveTime: string;
  number10MinIntervals: number;
  alarmCount: number;
  averageAlarm10MinInterval: number;
  maxAlarmCount10MinInterval: number;
}

export interface IStaleAlarm {
  alarm: string;
  activeTime: string;
  duration: string;
  message: string;
}

export interface IAlarmListResponse<T> {
  items: T[] ;
  nextCursor: string | null;
}

export interface IAlarmListTableData<T> {
  items: T[] | null;
  nextCursor: string | null;
}
export interface IAlarmListDownloadResponse {
  url: any;
}

export interface IAlarmLoader {
  isLoadingAlarms: boolean;
  isLoadingAlarmsByDuration: boolean;
  isLoadingAlarmsByFrequency: boolean;
  isLoadingAlarmsByEventType: boolean;
  isLoadingAutomaticDiagnosticEvent: boolean;
  isLoadingAlarmKPI: boolean;
}

export enum AlarmEventType {
  DETAILED = 'Detailed',
  CHATTERING = 'Chattering',
  FLEETING = 'Fleeting',
  FLOODING = 'Flooding',
  STALE = 'Stale',
  DIAGNOSTIC_EVENT = 'Diagnostic Event',
}
export enum AlarmDashboardDrilldownType {
  TOTAL_COUNT_MONTHLY = 'monthly_total_count',
  DAILY_COUNT = 'daily_count',
  HOURLY_COUNT = 'hourly_count',
  ALARM_STATE = 'state',
  ALARM_PRIORITY = 'priority',
  ALARM_CLASS = 'class',
  ALARM_AREA = 'area'
}
export interface IAutomaticDiagnosticEvent {
  deviceName: string;
  state: string;
  assessment: string;
  eventTime: string;
  catalog: string;
  message: string;
}

export interface IAutomaticDiagnosticEventDetail extends IAutomaticDiagnosticEvent {
  devicePath: string;
  area: string;
  productName: string;
  diagnosticCode: string;
  resource: string;
  serverName: string;
  productType: string;
  vendor: string;
  majorRevision: string;
  minorRevision: string;
}

export type IAlarmsByEventType = IDetailedAlarmDetail | IChatteringAlarm | IFleetingAlarm | IFloodingAlarm | IStaleAlarm;

export interface IAlarmKPIChart {
  chartValue: string;
  formatDates?: boolean;
  items: IAlarmKPIItem[];
}
export interface IAlarmKPICount {
  tooltip: string | undefined;
  data: IAlarmKPIItem[];
}
export interface IAlarmKPICharts {
  topBadActors: any | undefined;
  fleetingAlarmCount: IAlarmKPIChart | undefined;
  chatteringAlarmCount: IAlarmKPIChart | undefined;
  staleAlarmCount: IAlarmKPIChart | undefined;
  floodingAlarmPercentage: IAlarmKPIChart | undefined;
  averageAlarmRate: IAlarmKPIChart | undefined;
  previousAverageAlarmRate: IAlarmKPIChart | undefined;
}